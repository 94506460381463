import React from 'react'
import classNames from 'classnames'


import './choice.module.css'

const Choice = (props) => (
  <div styleName={classNames('choice', {'active': props.currentChoice === props.choiceId})} onClick={() => props.onClick(props.choiceId)}>
    <img styleName={'image'} src={props.imgSrc} alt={props.imgAlt} />
    <div styleName={'label'}>{props.label}</div>
  </div>
);

export default Choice;

import React from 'react'
import Page from '../components/Page/Page'
import Picker from '../components/Picker/Picker'


import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const InstrumentePage = ({ data }) => {
  const contentDict = [
     data.pickerTexte.schuelerViolinenText.json,
     data.pickerTexte.schuelerViolaText.json,
     data.pickerTexte.schuelerCelloText.json,
     data.pickerTexte.schuelerBassText.json
   ];

  return (
    <Page
      title={data.pickerTexte.title}
      renderCallback={() => (
        <div>
          <div>
            {documentToReactComponents(data.pickerTexte.meisterInstrumenteText.json)}
          </div>
          <div>
            {documentToReactComponents(data.pickerTexte.schuelerInstrumenteText.json)}
          </div>
          <Picker
            content={contentDict}
            customContent={false}
          />
        </div>
      )}
    />
  )
}

export default InstrumentePage

export const instrumenteQueryFragment = graphql `
  fragment InstrumenteQueryFragment on ContentfulInstrumenteContent {
    title
    meisterInstrumenteText {
      json
    }
    schuelerInstrumenteText {
      json
    }
    schuelerBassText {
      json
    }
    schuelerCelloText {
      json
    }
    schuelerViolaText {
      json
    }
    schuelerViolinenText {
      json
    }
  }
`

export const urlFragment = graphql `
  fragment URLFragment on ContentfulAsset {
    file {
      url
    }
  }
`

export const instrumenteQuery = graphql `
  query instrumentDEQuery {
    pickerTexte: contentfulInstrumenteContent(node_locale: {eq: "de"}) {
      ...InstrumenteQueryFragment
    }
  }
`

import React from 'react'
import Choice from './Choice'
import './picker.module.css';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import { Location } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
      <img src={`https://${data.target.fields.file.de.url}`} alt={data.target.fields.description.en} />
    )
  }
};

class Picker extends React.Component  {

  constructor(props) {
    super(props);
    this.state = { currentChoice: 0 };
  }

  onChoiceClick = (choiceId) => {
    this.setState(() => ({
      currentChoice: choiceId,
    }));
  };

  getUrl = (data, fileName) => {
    return data.allContentfulAsset.nodes.reduce((acc, it) => {
      if (it.file.fileName === fileName) {
        acc = acc + it.file.url;
      }
      return acc;
      }
    , 'https:');
  };

  render() {

    return (
      <Location>
        {({ location }) => {
          const onENRoute = (location.pathname.indexOf('/en/') !== -1);
          const violinNameLocal = onENRoute ? 'Violin' : 'Violine';

          return (
            <StaticQuery
                query={pickerImagesQuery}
                render={data => {
                  const content = {
                    [violinNameLocal]: {
                        'choiceId': 0,
                        'imgURL': this.getUrl(data, 'geige.png'),
                      },
                      'Viola': {
                        'choiceId': 1,
                        'imgURL': this.getUrl(data, 'geige.png'),
                      },
                      'Cello': {
                        'choiceId': 2,
                        'imgURL': this.getUrl(data, 'cello.png'),
                      },
                      'Bass': {
                        'choiceId': 3,
                        'imgURL': this.getUrl(data, 'bass.png'),
                      }
                  }

                  return (
                    <div styleName={'picker'}>
                      <div styleName={'choicesContainer'}>
                        {Object.keys(content).map(key => (
                          <Choice
                            label={key}
                            choiceId={content[key].choiceId}
                            onClick={this.onChoiceClick}
                            currentChoice={this.state.currentChoice}
                            imgSrc={content[key].imgURL}
                            imgAlt={key}
                            key={key}
                          />
                      ))}
                      </div>
                      <div styleName={'contentDisplay'}>
                        {!this.props.customContent ?
                        documentToReactComponents(this.props.content[this.state.currentChoice], options)
                        :
                        this.props.content[this.state.currentChoice]
                        }
                      </div>
                    </div>
                  )
                }}
            />
          );
        }}
        </Location>
    )
  }
}

export default Picker;

export const pickerImagesQuery = graphql `
  query PickerImagesQuery {
    allContentfulAsset(filter: {file: {fileName: {in: ["geige.png", "bass.png", "cello.png"]}}, node_locale: {eq: "de"}}) {
      nodes {
        file {
          fileName
          url
        }
      }
    }
  }
`
